<template>
  <div>
    <v-row class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
          Reset Password
        </h2>
        <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
          Enter the email associated with your account. We’ll send a link to
          reset your password.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-form @submit.prevent="submitPasswordReset" ref="sendResetLink">
          <validation-observer ref="observer" v-slot="{}">
            <v-row>
              <v-col>
                <!--empty string prevents matchEmail from throwing error on logout-->
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="`required|email|matchEmail:${user.email || ''}`"
                >
                  <j-text-field
                    color="secondary"
                    v-model="email"
                    type="email"
                    rounded
                    outlined
                    required
                    :hide-details="errors.length === 0"
                    placeholder="Enter email address"
                    :prepend-inner-icon="'mdi-email-outline'"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </validation-observer>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title
          class="tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"
          >Password Reset Confirmation</v-card-title
        >
        <v-card-text class="tw-text-base"
          >This action will log you out of the app. Are you sure you want to
          continue?</v-card-text
        >
        <v-card-actions class="tw-justify-end">
          <button class="btn-tertiary tw-mx-4" @click="dialog = !dialog">
            Cancel
          </button>
          <v-btn
            rounded
            color="primary"
            @click="submitPasswordReset"
            class="tw-px-4 tw-tracking-normal"
            >Log out</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  props: ["user"],
  data() {
    return {
      email: null,
      dialog: false,
    };
  },
  methods: {
    submitPasswordReset() {
      this.$store
        .dispatch("create-password-reset/create", {
          email: this.user.email,
        })
        .then(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/auth");
        });
    },
  },
  computed: {
    emailsMatch() {
      if (
        this.user &&
        this.email !== null &&
        this.user.email !== null &&
        this.email !== "" &&
        this.user.email !== ""
      ) {
        return this.email === this.user.email;
      }
      return false;
    },
  },
  watch: {
    emailsMatch(newValue) {
      if (newValue) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false,
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true,
        });
      }
    },
  },
  beforeMount() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      isDisabled: true,
      text: "Send Reset Link",
      method: () => {
        this.dialog = !this.dialog;
      },
      bgColor: "tw-bg-chartreuse",
      textColor: "tw-text-charcoal",
    });
  },
};
</script>
